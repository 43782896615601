/* istanbul ignore file */
import React from 'react';
import type { CookieObj } from '@noths/polaris-client-gdpr-compliance';
import { acceptsFunctionalCookies } from '@noths/polaris-client-gdpr-compliance';
import type { GetServerSideProps, InferGetServerSidePropsType } from 'next';

import { RecommendationsContextProvider } from 'src/components/contexts/RecommendationsContext';
import { RibbonsPage } from 'src/components/templates/RibbonsPage/RibbonsPage';
import { SKIP_CONTENT_TARGET_ID } from 'src/constants/elementIds';
import { getPageContent } from 'src/content/getPageContent';
import type { ContentPageProps, PageConfiguration, PageMetadata } from 'src/types/PageProps';
import { getPageMetadata } from 'src/utils/getPageMetadata';
import { getRecommendationsSessionId } from 'src/utils/getRecommendationsSessionId';

const pageConfig: PageConfiguration = {
  env: process.env.NODE_ENV,
  contentType: 'department',
  pageType: 'department',
  trackingCategory: 'Department',
};

export const getServerSideProps: GetServerSideProps<ContentPageProps> = async (context) => {
  const { req } = context;

  const pageMetadata: PageMetadata = {
    ...getPageMetadata(context),
    functionalCookiesConsented: acceptsFunctionalCookies(req.cookies as CookieObj),
    recommendationsSessionId: getRecommendationsSessionId(context),
  };

  try {
    const pageContent = await getPageContent(pageConfig, pageMetadata);

    return {
      props: {
        pageConfig,
        pageMetadata,
        pageContent: pageContent.pageContent,
        pageEventCategory: pageConfig.trackingCategory,
        navData: pageContent.navData,
        topBannerData: pageContent.topBannerData,
      },
    };
  } catch {
    return {
      notFound: true,
    };
  }
};

const DepartmentPage = (props: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  return (
    <div id={SKIP_CONTENT_TARGET_ID}>
      <RecommendationsContextProvider {...props}>
        <RibbonsPage {...props} />
      </RecommendationsContextProvider>
    </div>
  );
};

export default DepartmentPage;
